import classnames from 'classnames';
import * as React from 'react';

export interface IExperlogixIframeHostProps {
    iframeRef: React.Ref<HTMLIFrameElement>;
    experlogixUrl: string;
    height?: string;
    width?: string;
    ariaLabel?: string;
}

export default class ExperlogixIframeHost extends React.PureComponent<IExperlogixIframeHostProps> {
    public render(): JSX.Element | null {
        const { iframeRef, experlogixUrl, height, width, ariaLabel } = this.props;

        return (
            // tslint:disable-next-line: react-iframe-missing-sandbox
            <iframe
                className={classnames('exp-iframe__content')}
                src={experlogixUrl}
                height={height}
                width={width}
                aria-label={ariaLabel}
                ref={iframeRef}
            />
        );
    }
}
